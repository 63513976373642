<script setup lang="ts">
defineProps({
    outlineColor: {
        type: String,
        default: "#6A799C"
    },
    fillColor: {
        type: String,
        default: "#50A0EB"
    },
    solid: {
        type: Boolean,
        default: true
    }
});

const randomId = computed(() => Math.random().toString(36).substr(2, 9));
</script>

<template>
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g :clip-path="'url(#clip0_973_10657' + randomId + ')'">
            <path
                v-if="!solid"
                d="M8.99998 16.9986C8.74373 16.9986 8.49667 16.9057 8.30414 16.7371C7.57698 16.1013 6.87592 15.5037 6.25739 14.9767L6.25423 14.9739C4.4408 13.4286 2.87484 12.094 1.78527 10.7794C0.567305 9.30966 0 7.91619 0 6.3939C0 4.91486 0.507155 3.55036 1.42795 2.55157C2.35972 1.54097 3.63825 0.984375 5.02843 0.984375C6.06746 0.984375 7.01901 1.31287 7.85658 1.96065C8.27928 2.28763 8.66243 2.6878 8.99998 3.15458C9.33767 2.6878 9.72069 2.28763 10.1435 1.96065C10.9811 1.31287 11.9326 0.984375 12.9717 0.984375C14.3617 0.984375 15.6404 1.54097 16.5722 2.55157C17.4929 3.55036 18 4.91486 18 6.3939C18 7.91619 17.4328 9.30966 16.2148 10.7792C15.1253 12.094 13.5594 13.4284 11.7463 14.9737C11.1267 15.5015 10.4245 16.1 9.69569 16.7374C9.50329 16.9057 9.2561 16.9986 8.99998 16.9986ZM5.02843 2.03879C3.93626 2.03879 2.93293 2.47467 2.20303 3.26623C1.46228 4.06974 1.05427 5.18046 1.05427 6.3939C1.05427 7.67421 1.53012 8.81926 2.59703 10.1066C3.62823 11.3509 5.16205 12.658 6.93799 14.1715L6.94129 14.1743C7.56215 14.7034 8.26596 15.3032 8.99847 15.9437C9.73538 15.302 10.4403 14.7012 11.0624 14.1712C12.8382 12.6577 14.3719 11.3509 15.4031 10.1066C16.4698 8.81926 16.9457 7.67421 16.9457 6.3939C16.9457 5.18046 16.5377 4.06974 15.7969 3.26623C15.0672 2.47467 14.0637 2.03879 12.9717 2.03879C12.1716 2.03879 11.437 2.29312 10.7884 2.79464C10.2104 3.24179 9.80775 3.80703 9.57168 4.20254C9.45028 4.40592 9.2366 4.52732 8.99998 4.52732C8.76336 4.52732 8.54968 4.40592 8.42828 4.20254C8.19235 3.80703 7.7897 3.24179 7.21155 2.79464C6.56295 2.29312 5.82837 2.03879 5.02843 2.03879Z"
                :fill="outlineColor"
            />
            <path
                v-else
                d="M16.5721 2.55157C15.6403 1.54097 14.3617 0.984375 12.9717 0.984375C11.9327 0.984375 10.9811 1.31287 10.1434 1.96065C9.7207 2.28763 9.33769 2.68767 9 3.15459C8.66245 2.68781 8.2793 2.28763 7.85646 1.96065C7.01889 1.31287 6.06734 0.984375 5.02831 0.984375C3.63826 0.984375 2.35959 1.54097 1.42781 2.55157C0.507156 3.55037 0 4.91487 0 6.39391C0 7.9162 0.567307 9.30968 1.78528 10.7794C2.87485 12.094 4.44081 13.4286 6.25424 14.974C6.87346 15.5017 7.57535 16.0999 8.30415 16.7371C8.49669 16.9058 8.74374 16.9986 9 16.9986C9.25612 16.9986 9.50331 16.9058 9.69557 16.7374C10.4244 16.1001 11.1267 15.5016 11.7462 14.9735C13.5593 13.4285 15.1253 12.094 16.2149 10.7792C17.4328 9.30968 18 7.9162 18 6.39377C18 4.91487 17.4928 3.55037 16.5721 2.55157Z"
                fill="currentColor"
            />
        </g>
        <defs>
            <clipPath :id="'clip0_973_10657' + randomId">
                <rect width="18" height="18" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
